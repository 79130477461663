export default {
    state: {
        menu: [
            { product: true, title: 'Tax Compliance', bgcolor: 'ccompliance' },
            // { button: true, title: 'Create Analysis', href: '/tds/ar#add' },
            // {
            //   title: 'Dashboard', icon: 'mdi mdi-view-dashboard-outline', href: '/pbc/dashboard',
            // },
            { header: true, title: 'Analysis' },
            {
                title: 'Accounts Receivable', icon: 'mdi mdi-alpha-r-circle-outline', href: '/tds/ar', right: "tds_ar_project"
            },
            {
                title: 'Accounts Payable', icon: 'mdi mdi-alpha-p-circle-outline', href: '/tds/ap', right: "tds_ap_project"
            },
            {
                title: 'Tax Compliance', icon: 'mdi mdi-alpha-m-circle-outline', href: '/tds/monthly', right: "tds_monthly"
            },
            { header: true, title: 'Manage' },
            {
                title: 'Vendor Master', icon: 'mdi mdi-briefcase-account-outline', href: '/tds/vm',
            },
            {
                title: 'Input Parameters Config', icon: 'mdi-dns-outline', href: '/tds/ipconfig', right: "tds_monthly", action: ["review", "add", "edit"] 
            },
            {
                title: 'File Config ', icon: 'mdi-file-link ', href: '/tds/filelist', right: "tds_monthly", action: ["review", "add", "edit"] 
            },
        ],
    },
    mutations: {
    },
    actions: {
    },
    getters: {

    },
}